import React from "react";
import { Box } from "@mui/material";
import WebServices from "./WebServices";
import MenuBar from "../MenuBar";
import Footer from "../../Footer/Footer";

const ServicesPage = () => {

    return (
        <Box>
            <MenuBar/>
            <WebServices/>
            <Footer/>
        </Box>
    )
}

export default ServicesPage;