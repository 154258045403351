import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const trackingId = 'G-YEW0NSSTFT'; //From GA Account

const useGoogleAnalytics = () => {
  const location = useLocation();

  //EVETN TRACKING
  const trackEvent = (category: string, action: string, label: string) => {
    ReactGA.event({
      category,
      action,
      label,
    });
  };

  useEffect(() => {
    if (trackingId) {
      ReactGA.initialize(trackingId);
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }
  }, [location]);

  return { trackEvent };

};

export default useGoogleAnalytics;
