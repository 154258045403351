import React, {useEffect} from "react";
import logo from "./logo.svg";
import "./Components/Styles/fonts.css";
import MenuBar from "./Components/Pages/MenuBar";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./Components/Theme/theme";
import Home from "./Components/Pages/Home/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Contact from "./Components/Pages/ContactUs/ContactUs";
import ServicesPage from "./Components/Pages/Services/ServicesPage";
import ReactGA from 'react-ga4';
import useGoogleAnalytics from "./Services/GoogleAnalytics/useGoogleAnalytics";


function App() {
  // initialsing google analytics
//const googleAnalytics_id = 'G-YYWZC49X3S'


  return (
    <ThemeProvider theme={theme}>
     
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contactus" element={<Contact />} />
          <Route path="/services" element={<ServicesPage />} />
        </Routes>
      
    </ThemeProvider>
  );
}

export default App;
