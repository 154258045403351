import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Paper } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const services = [
  {
    title: 'Consultation and Planning',
    details: [
      'Conduct a thorough needs assessment to align with your goals and understand your target audience.',
      'Perform competitor analysis and industry research to identify opportunities and trends.',
      'Develop a clear website architecture, user experience (UX) design, and content strategy tailored to your objectives.',
    ],
  },
  {
    title: 'Domain and Hosting Services',
    details: [
      'Domain registration and management.',
      'Hosting setup (shared, dedicated, cloud hosting options).',
      'SSL certificate provisioning for secure connections.',
    ],
  },
  {
    title: 'Website Design',
    details: [
      'Custom website design or theme-based design.',
      'User interface (UI) design for an intuitive and visually appealing layout.',
      'Mobile-responsive design to ensure the site works on all devices.',
    ],
  },
  {
    title: 'Content Management Systems (CMS)',
    details: [
      'Implementation of CMS platforms for you to edit content on your website.',
      'Training and support for clients to manage website content.',
      'Custom CMS solutions for advanced needs.',
    ],
  },
  {
    title: 'Website Development',
    details: [
      'Front-end development (HTML, CSS, JavaScript, frameworks like React or Angular).',
      'Back-end development (server-side programming in languages like Python, or Node.js).',
      'Database integration and management.',
      'E-commerce development (shopping cart, payment gateways, etc.).',
    ],
  },
  {
    title: 'Search Engine Optimization (SEO)',
    details: [
      'On-page SEO (keyword optimization, meta tags, alt text).',
      'Technical SEO (site speed, mobile-friendliness, schema markup).',
      'Ongoing SEO strategy for content and link building.',
    ],
  },
  {
    title: 'Content Creation',
    details: [
      'Copywriting for pages, blogs, and product descriptions.',
      'Visual content creation (graphics, videos, animations).',
      'Branding services (logo design, typography, color schemes).',
    ],
  },
  {
    title: 'Website Security',
    details: [
      'Setting up firewalls, security patches, and regular updates.',
      'Malware scanning and removal services.',
      'Data encryption and compliance with data privacy regulations.',
    ],
  },
  {
    title: 'Website Maintenance and Support',
    details: [
      'Regular updates (CMS, plugins, themes).',
      'Bug fixing and troubleshooting.',
      'Performance monitoring and improvements.',
      'Backup and restore services.',
    ],
  },
  {
    title: 'Digital Marketing Services',
    details: [
      'Social media integration and marketing strategies.',
      'Email marketing setup (e.g., newsletter sign-ups, drip campaigns).',
      'Paid advertising management (Google Ads, Facebook Ads).',
    ],
  },
  {
    title: 'Analytics and Reporting',
    details: [
      'Setting up Google Analytics or other tracking tools.',
      'Conversion rate optimization (CRO) analysis.',
      'Monthly or quarterly reports on website performance.',
    ],
  },
];

const WebServices: React.FC = () => {
  return (
    <Grid container spacing={3} style={{ padding: '20px', marginTop: '10vh' }}>
      <Grid item xs={12}>
        <Typography variant="h3" align="center" gutterBottom>
          Our Services
        </Typography>
      </Grid>
      
      {services.map((service, index) => (
        <Grid item xs={12} md={6} key={index}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography variant="h6">{service.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  {service.details.map((detail, i) => (
                    <li key={i}>
                      <Typography>{detail}</Typography>
                    </li>
                  ))}
                </ul>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default WebServices;
