import React from "react";
import { Box, Typography, Link, IconButton, Stack } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const Footer = () => {
  const isMobile = useMediaQuery("(max-width:600px)"); // Detects if the screen is mobile
const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        padding: isMobile ? 2 : 5, // Adjust padding based on screen size
        textAlign: "center",
        paddingTop: isMobile?4:8,
        marginTop: isMobile?5:8
      }}
    >
      <Stack
        direction={isMobile ? "column" : "row"} // Stack items vertically on mobile, horizontally on larger screens
        spacing={isMobile ? 2 : 5}
        justifyContent="space-between"
        alignItems="center"
      >
        {/* Footer Links */}
        <Box>
          <Typography variant="h6">Quick Links</Typography>
          <Stack spacing={1}>
            <Link href="/" color="inherit" underline="hover">
              Home
            </Link>
            <Link href="/services" color="inherit" underline="hover">
              Services
            </Link>
            <Link href="/contactus" color="inherit" underline="hover">
              Contact
            </Link>
          </Stack>
        </Box>

        {/* Contact Information */}
        <Box>
          <Typography variant="h6">Contact Us</Typography>
          <Typography>Narre Warren, VIC 3805, Australia</Typography>
          <Typography>Email: admin@mangowebdesigns.com</Typography>
          {/* <Typography>Phone: +1 234 567 8900</Typography> */}
        </Box>

        {/* Social Media Icons */}
        {/* <Box>
          <Typography variant="h6">Follow Us</Typography>
          <Stack direction="row" spacing={1} justifyContent="center">
            <IconButton
              href="https://facebook.com"
              color="inherit"
              target="_blank"
              aria-label="Facebook"
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              href="https://twitter.com"
              color="inherit"
              target="_blank"
              aria-label="Twitter"
            >
              <TwitterIcon />
            </IconButton>
            <IconButton
              href="https://instagram.com"
              color="inherit"
              target="_blank"
              aria-label="Instagram"
            >
              <InstagramIcon />
            </IconButton>
          </Stack>
        </Box> */}
      </Stack>

      {/* Copyright Section */}
      <Box
        sx={{
          marginTop: 5,
          borderTop: "1px solid rgba(255, 255, 255, 0.2)",
          paddingTop: 2,
        }}
      >
        <Typography variant="body2" color="inherit">
          © {new Date().getFullYear()} Mango Web Designs. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
