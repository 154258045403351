import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { isMap } from 'util/types';

interface ResponsiveBlockProps {
  heading: string;
  body: string;
  left: boolean
}

const InfoBlock: React.FC<ResponsiveBlockProps> = ({ heading, body, left }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check for phone view

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      width={isMobile ? '100%' : '50%'} // 100% for mobile, 50% for laptop
      height={isMobile?'60vh':"100vh"} // Full height of the viewport
      maxWidth={isMobile?'100vw':'50vw'}
      sx={{
        width: isMobile?'100vw':'50vw'
      }}
    >
      {/* Heading Section */}
      <Box
        height="70%" // Half of the viewport height
        display="flex"
        alignItems="center"
        justifyContent= {left? "flex-end":'flex-start'}
        maxWidth={isMobile?'100vw':'50vw'}
        padding={isMobile?2:5}
        // sx={{
        //   backgroundColor: '#f0f0f0', // Optional background color for heading
        // }}
      >
        <Typography sx={{fontSize: isMobile?'7vh':'10vh', fontFamily: 'bungeeshade'}} align={left?'right':'left'}>
          {heading}
        </Typography>
      </Box>

      {/* Body Section */}
      <Box
        height="50%" // Other half of the viewport height
        display="flex"
        justifyContent={left? "flex-end" : 'flex-start'}
        padding={isMobile?2:5}
        sx={{
          backgroundColor: '#ffffff', // Optional background color for body
        }}
      >
        <Typography variant={isMobile?'h5':"h4"} align={left?'right':'left'}>
          {body}
        </Typography>
      </Box>
    </Box>
  );
};

export default InfoBlock;
