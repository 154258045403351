import React, { useState, useEffect } from "react";
import { Box, TextField, Button, Typography, Stack } from "@mui/material";
import axios from "axios";
import MenuBar from "../MenuBar";
import mangobg from "../../../Images/mangoImg1.jpg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Footer from "../../Footer/Footer";
import useGoogleAnalytics from "../../../Services/GoogleAnalytics/useGoogleAnalytics";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Google Analytics
  useGoogleAnalytics();
  const {trackEvent} = useGoogleAnalytics();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    // Google Analytics
    trackEvent('Button', 'Click', 'Contact form submitted');

    e.preventDefault();
    setLoading(true);

    // try {
    // Send the form data to the backend
    // const response = await axios.post(
    //   "https://nsftwp7k4m.execute-api.ap-southeast-2.amazonaws.com/mangowebdesigns/mangowebdesigns/contact",
    //   { name, email, message }
    // );
    //   const response = await axios.get(
    //     "https://nsftwp7k4m.execute-api.ap-southeast-2.amazonaws.com/mangowebdesigns/"
    //   );

    //   if (response.status === 200) {
    //     setSuccess(true);
    //     setName("");
    //     setEmail("");
    //     setMessage("");
    //   }
    //   console.log("Sucessfully send enquiry");
    // } catch (err) {
    //   setError(
    //     "There was an issue submitting your enquiry. Please try again later."
    //   );
    //   console.log("Could not send enquiry");
    // } finally {
    //   setLoading(false);
    // }
    axios
      .post(
        "https://nsftwp7k4m.execute-api.ap-southeast-2.amazonaws.com/mangowebdesigns/mangowebdesigns/contact",
        { name, email, message },
        {
          timeout: 10000, // Timeout set to 10 seconds
        }
      )
      .then((response) => {
        //console.log(response);
        setLoading(false);
        setSuccess(true);
        setTimeout(() => setSuccess(false), 5000)
      })
      .catch((error) => {
        if (error.code === "ECONNABORTED") {
          console.error("Request timed out");
        } else {
          console.error("There was an error fetching the entries!", error);
        }
        setLoading(false);
      });
  };

  // USE EFFECTS
  useEffect(() => {
    document.title = 'Contact Us';
  }, []);

  return (
    <Box>
      <Box sx={{ paddingY: 15, maxWidth: 600, margin: "auto", paddingX: isMobile?3:1 }}>
        <MenuBar />

        <Typography
          variant="h1"
          sx={{
            textAlign: "center",
            fontFamily: "poppins",
            fontWeight: "bold",
          }}
          gutterBottom
        >
          Enquire about your new project
        </Typography>
        <Box paddingY={1}>
          <Typography variant="body1" fontWeight="light" gutterBottom>
            Whether it’s launching a new site, re-imaging an existing one, Mango
            Web Designs can find the solution that satisfies your expectations
          </Typography>

          <form onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                label="Name"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                required
              />
              <TextField
                label="Email"
                type="email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                required
              />
              <TextField
                label="Message"
                variant="outlined"
                multiline
                rows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                fullWidth
                required
              />

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
                fullWidth
              >
                {loading ? "Sending..." : "Send Message"}
              </Button>

              {success && (
                <Typography variant="body1" color="green">
                  Your message has been sent successfully.
                </Typography>
              )}

              {/* {!success && (
                <Typography variant="body1" color="error">
                  {error}
                </Typography>
              )} */}
            </Stack>
          </form>
        </Box>
      </Box>
      <Box>
        <Footer />
      </Box>
    </Box>
  );
};

export default Contact;
